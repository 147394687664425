/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import 'bootstrap';

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Google Analytics cookies
 */

window["ga-disable-UA-154977561-1"] = true;



/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
} else {
    //console.error("CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token");
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = import('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });

// $.validator.setDefaults({
//     wrapper: "div",
//     ignore: [],
//     errorPlacement: error => {
//         error.addClass("invalid-feedback");
//     },
//     highlight: element => {
//         $(element)
//             .parents(".form-group")
//             .addClass("has-error");
//     },
//     unhighlight: element => {
//         $(element)
//             .parents(".form-group")
//             .removeClass("has-error");
//     }
// });

/**
 *
 * Generic functions
 */
window.ajaxGetCall = action => {
    return window.axios.get(action).then(response => {
        if (response.data) {
            return response.data;
        }
    });
};

window.ajaxPostCall = (action, data) => {
    return window.axios
        .post(action, data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        .then(response => {
            if (response.data) {
                return response.data;
            }
        });
};

// Add a tag element and add the id to the hidden input
window.addElement = (type, id = "", name = "") => {
    // Get the values from the selected options
    if (id === "" && name === "") {
        id = $("#" + type + " option:selected").val();
        name = $("#" + type + " option:selected").text();
    }

    if (id !== "" && name !== "") {
        // Set the values to send
        let ids = $("#" + type + "s").val();
        ids = ids !== "" ? ids + "," : "";

        let container = $("#" + type + "s-items").html();
        if (container.indexOf('data-id="' + id + '"') < 0) {
            // Show the tags and save value
            $("#" + type + "s").val(ids + id);
            $("#" + type + "s-items").append(
                `<span class='tag-item' data-id='${id}' data-input='${type}'>${name} <i>x</i></span>`
            );
            $(".tag-item i").on("click", window.removeTag);
        }

        // Reset the selects
        $("#" + type).val("");
    }
};

// Add tags when editing artwork
window.addArray = (array, type) => {
    for (let i = 0; i < array.length; i++) {
        const unit = array[i];
        window.addElement(type, unit.id, unit.name);
    }
};

// Toggle field in database
window.toggleField = event => {
    let element = event.target;
    return window
        .ajaxGetCall(
            APP_URL + $(element).attr("data-url") + $(element).attr("data-id")
        )
        .then(() => {});
};

//Removes tags when they have been added from hidden input and from div
window.removeTag = e => {
    let itemId = $(e.target)
        .parent()
        .attr("data-id");
    let itemInput = $(e.target)
        .parent()
        .attr("data-input");
    let values = $("#" + itemInput + "s").val();
    if (typeof values !== "undefined" && values !== "") {
        values = values.split(",");
        let newValues = [];
        for (let i = 0; i < values.length; i++) {
            if (values[i] !== itemId) {
                newValues.push(values[i]);
            }
        }
        $("#" + itemInput + "s").val(newValues.join());
    }
    $(e.target)
        .parent()
        .remove();
};
